<template>
  <PromiseContainer :promises.sync="computedPromises">
    <v-card flat outlined>
      <!-- <DeleteItemDialog v-if="displayDeleteDialog" :message="deleteMessageFunction(itemToDelete)"
        :store-action="deleteAction" :item-payload="deletePayload" @close="closeDelete" /> -->
      <ConfirmationDialog v-model="displayDeleteDialog" :description="computedConfirmationDialogDescription"
        :title="confirmationDialogTitle" :onConfirm="() => confirmationDialogOnConfirm(itemToDelete)" />
      <v-toolbar class="background--cultured-gray" flat>
        <v-toolbar-title class="font-weight-medium">
          {{ title }}
        </v-toolbar-title>
        <v-spacer />
        <router-link v-if="addRouterLink" :to="addRouterLink" class="text-decoration-none clickable">
          <v-icon dense class="vertical-align-baseline mr-2">{{ addIconName }}</v-icon>
        </router-link>
      </v-toolbar>
      <v-divider />

      <!--inner v-card used for scroll behavior -->
      <v-card :height="listHeight" class="overflow-y-auto">
        <v-list-item-group>
          <div v-for="(item, index) in items" :key="index">
            <BasicInformationListItem :value="item" @delete="openDelete">
              <template v-slot:title-display="{ value }">
                <!-- This is a pass-through slot that determines the content of each v-list-item -->
                <slot name="title-display" :value="value"></slot>
              </template>
            </BasicInformationListItem>
            <v-divider v-if="index < items.length - 1"></v-divider>
          </div>
        </v-list-item-group>
      </v-card>
    </v-card>
  </PromiseContainer>
</template>

<script>
import PromiseContainer from '@/components/shared/PromiseContainer.vue'
import BasicInformationListItem from './BasicInformationListItem.vue'
import ConfirmationDialog from './ConfirmationDialog.vue'

/**
 * A list version of BasicInformationCard
 */

export default {
  name: "BasicInformationListCard",
  props: {
    confirmationDialogTitle: {
      type: String,
      required: false
    },
    confirmationDialogDescription: {
      type: Function,
      required: false
    },
    confirmationDialogOnConfirm: {
      type: Function,
      required: false
    },
    title: {
      type: String,
      required: true
    },
    addRouterLink: {
      type: Object,
      required: false,
      default: null
    },
    addIconName: {
      type: String,
      required: false,
      default: "mdi-plus-thick"
    },
    deleteMessageFunction: {
      type: Function,
      required: false,
    },
    deletePayloadFunction: {
      type: Function,
      required: false,
    },
    deleteAction: {
      type: String,
      required: false,
    },
    items: {
      type: Array,
      required: true,
      //default: () => []
    },
    listHeight: {
      type: String,
      required: false,
      default: "16rem"
    },
    promises: []
  },
  emits: ['update:promises'],
  components: {
    PromiseContainer,
    BasicInformationListItem,
    ConfirmationDialog
  },
  data() {
    return {
      displayDeleteDialog: false,
      itemToDelete: null,
      deletePayload: null,
    }
  },
  computed: {
    computedConfirmationDialogDescription() {
      return this.confirmationDialogDescription(this.itemToDelete)
    },
    computedPromises: {
      get() {
        return this.promises
      },
      set(newValue) {
        this.$emit('update:promises', newValue)
      }
    }
  },
  methods: {
    openDelete(item) {
      this.itemToDelete = item
      this.deletePayload = this.deletePayloadFunction ? this.deletePayloadFunction(item) : item.id
      this.displayDeleteDialog = true
    },
    closeDelete() {
      this.displayDeleteDialog = false
    },
  }
}
</script>