<template>

    <BasicInformationListCard :promises.sync="promises" title="Teamleden"
        :addRouterLink="{ name: RouteNames.TEAM_DETAIL_ADD_MEMBER, params: { teamId: teamId } }" :items="members"
        confirmationDialogTitle="Teamlid verwijderen" :confirmationDialogDescription="deleteDialogDescription"
        :confirmationDialogOnConfirm="deleteTeamMember">
        <template v-slot:title-display="{ value }">
            <v-list-item-title>{{ value.fullName }}</v-list-item-title>
        </template>
    </BasicInformationListCard>
</template>

<script>
import BasicInformationListCard from "@/components/shared/BasicInformationListCard.vue"
import RouteNames from "@/router/RouteNames"
export default {
    name: "TeamDetailMembersInformationListCard",
    props: {
        teamId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            RouteNames,
            promises: [this.fetchMembers()],
            members: []
        }
    },
    components: {
        BasicInformationListCard
    },
    computed: {

    },
    methods: {
        fetchMembers() {
            return this.$store.dispatch("teamsModule/fetchTeamMembers", this.teamId).then(result => this.members = result);
        },
        deleteDialogDescription(item) {
            return `Ben u zeker dat u teamlid ${item?.fullName} wilt verwijderen uit dit team?`
        },
        deleteTeamMember(item) {
            this.promises.push(
                this.$store.dispatch("teamsModule/removeTeamMember", { userId: item.userId, teamId: this.teamId })
                    .then(
                        () => this.promises.push(
                            this.fetchMembers()
                        )
                    )
            )
        }
    }
}
</script>