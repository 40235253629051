<template>
  <div>
    <v-row>
      <v-col>
        <TeamDetailHeaderComponent :teamId="teamId"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <TeamDetailLeadersInformationListCard :teamId="teamId"/>
      </v-col>
      <v-col cols="12" md="6">
        <TeamDetailMembersInformationListCard :teamId="teamId"/>
      </v-col>
      <v-col cols="12" md="6">
       <TeamDetailProjectsInformationListCard :teamId="teamId"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import RouteNames from "@/router/RouteNames"
import TeamDetailHeaderComponent from "@/components/teamDetail/TeamDetailHeaderComponent"
import TeamDetailLeadersInformationListCard from "@/components/teamDetail/TeamDetailLeadersInformationListCard"
import TeamDetailMembersInformationListCard from "@/components/teamDetail/TeamDetailMembersInformationListCard"
import TeamDetailProjectsInformationListCard from "../teamDetail/TeamDetailProjectsInformationListCard.vue"

export default {
  name: "TeamDetailView",
  components: {
    TeamDetailHeaderComponent,
    TeamDetailLeadersInformationListCard,
    TeamDetailMembersInformationListCard,
    TeamDetailProjectsInformationListCard

  },
  props: {
    teamId: {
      required: true,
      type: String
    },
  },
  data() {
    return {
      // RouteNames     
    }
  }
}
</script>
