<template>
    <BasicInformationListCard :promises.sync="promises" title="Actieve projecten voor team"
        :addRouterLink="{ name: RouteNames.TEAM_DETAIL_ADD_PROJECT, params: { teamId: teamId } }" :items="getProjects"
        confirmationDialogTitle="Project verwijderen uit team" :confirmationDialogDescription="deleteDialogDescription"
        :confirmationDialogOnConfirm="deleteTeamProject">
        <template v-slot:title-display="{ value }">
            <v-list-item-title>{{ value.clientName }}: {{ value.projectName }}</v-list-item-title>
        </template>
    </BasicInformationListCard>
</template>

<script>
import BasicInformationListCard from "@/components/shared/BasicInformationListCard.vue"
import RouteNames from "@/router/RouteNames"
export default {
    name: "TeamDetailProjectsInformationListCard",
    props: {
        teamId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            RouteNames,
            promises: [this.fetchTeam(),this.fetchClients()],
            team: {
                teamClients: []
            },
            clients: []
        }
    },
    components: {
        BasicInformationListCard
    },
    computed: {
        getProjects() {
            //The next 2 steps are needed to cross-reference with the .active in client and project entities
            const teamClients = this.$store.state.teamsModule.team.teamClients
                //1 remove inactive clients
                .filter((teamClient) => {
                    const client = this.clients.find((client) => client.id === teamClient.clientId)
                    return !(!client || client.active === false)
                })
                //2 remove inactive projects from the remaining clients
                .map((teamClient) => {
                    const client = this.clients.find((client) => client.id === teamClient.clientId)
                    teamClient.teamProjects = teamClient.teamProjects
                        .filter((teamProject) => {
                            const project = client.projects.find((project) => project.id === teamProject.projectId)
                            return !(!project || project.active === false)
                        })
                    return teamClient
                })

            // add corresponding clientName & clientId to each project (for easy access in the template later on)
            return teamClients.map((teamClient) => {
                teamClient.teamProjects = teamClient.teamProjects.map((teamProject) => { return { ...teamProject, clientName: teamClient.clientName, clientId: teamClient.clientId } })
                return teamClient
            })
                // flatmap to all projects
                .flatMap((teamClient) => teamClient.teamProjects)
        },
    },
    methods: {
        fetchTeam() {
            return this.$store.dispatch("teamsModule/fetchTeam", this.teamId).then(result => this.team = result);
        },
        deleteDialogDescription(item) {
            return `Ben u zeker dat u project ${item?.projectName} van klant ${item?.clientName} wilt verwijderen uit dit team?`
        },
        deleteTeamProject(item) {
            this.promises.push(
                this.$store.dispatch("teamsModule/deleteProjectInTeam", { clientId: item.clientId, teamId: this.teamId, projectId: item.projectId })
                    .then(
                        () => this.promises.push(
                            this.fetchTeam(),this.fetchClients()
                        )
                    )
            )
        },
        fetchClients() {
            return this.$store.dispatch("clientsModule/fetchNotArchivedClients")
                .then(result => {
                    this.clients = result
                })
        },
    }
}
</script>