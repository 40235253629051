<template>

    <BasicInformationListCard :promises.sync="promises" title="Ploegleiders"
        :addRouterLink="{ name: RouteNames.TEAM_DETAIL_ADD_LEADER, params: { teamId: teamId } }" :items="leaders"
        confirmationDialogTitle="Ploegleider verwijderen" :confirmationDialogDescription="deleteDialogDescription"
        :confirmationDialogOnConfirm="deleteTeamLeader">
        <template v-slot:title-display="{ value }">
            <v-list-item-title>{{ value.fullName }}</v-list-item-title>
        </template>
    </BasicInformationListCard>
</template>

<script>
import BasicInformationListCard from "@/components/shared/BasicInformationListCard.vue"
import RouteNames from "@/router/RouteNames"
export default {
    name: "TeamDetailLeadersInformationListCard",
    props: {
        teamId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            RouteNames,
            promises: [this.fetchLeaders()],
            leaders: []
        }
    },
    components: {
        BasicInformationListCard
    },
    computed: {

    },
    methods: {        
        fetchLeaders() {
            return this.$store.dispatch("teamsModule/fetchTeamLeaders", this.teamId).then(result => this.leaders = result);
        },
        deleteDialogDescription(item) {
            return `Ben u zeker dat u ploegleider ${item?.fullName} wilt verwijderen uit dit team?`
        },
        deleteTeamLeader(item) {
            this.promises.push(
                this.$store.dispatch("teamsModule/removeTeamLeader", {userId:item.userId,teamId:this.teamId})
                    .then(
                        () => this.promises.push(
                            this.fetchLeaders()
                        )
                    )
            )
        }
    }
}
</script>