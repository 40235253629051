<template>
  <div>
    <v-list-item>
      <v-list-item-content>
        <slot :value="value" name="title-display">
          <v-list-item-title v-text="value"/>
        </slot>
      </v-list-item-content>
      <v-list-item-action>
        <v-btn icon @click="$emit('delete', value)">
          <v-icon>mdi-minus-thick</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
  </div>
</template>

<script>
export default {
    name: "BasicInformationListItem",
    model: {
        prop: "value",
        event: "update:value"
    },
    props: {
        description: String,
        value: null,
    },
}
</script>