<template>
  <div>
    <BasicDetailHeaderComponent
        :promises.sync="promises"
        default-avatar="default-team-avatar.svg"
        :title="team.teamName"
        :titleEditRoute="{name: routeNames.TEAM_DETAIL_EDIT_TEAM_NAME}"
        :return-link-route="{name: routeNames.TEAM_OVERVIEW}"
        return-link-text="Terug naar teams"
        :active="team.active"
        active-text="Actief team"
        inactive-text="Inactief team"
        menu-button-text="Acties">
      <template v-slot:actions>
        <v-list-item link @click="onToggleActiveClick">
          <v-list-item-icon>
            <v-icon>{{ team.active ? "mdi-folder-cancel" : "mdi-folder-check" }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ team.active ? "Zet op inactief" : "Zet op actief" }}</v-list-item-title>
        </v-list-item>
        <v-list-item link @click="onDeleteTeamClick">
          <v-list-item-icon>
            <v-icon>mdi-delete</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Verwijder</v-list-item-title>
        </v-list-item>
      </template>
    </BasicDetailHeaderComponent>
    <ConfirmationDialog :title="getToggleActiveDialogTitle" :description="getToggleActiveDialogDescription"
                        v-model="toggleActiveDialog" :onConfirm="toggleActive"/>
    <ConfirmationDialog :title="getDeleteDialogTitle" :description="getDeleteDialogDescription" v-model="deleteDialog"
                        :onConfirm="deleteTeam"/>
  </div>
</template>

<script>
import BasicDetailHeaderComponent from '../shared/BasicDetailHeaderComponent.vue';
import routeNames from "@/router/RouteNames";
import ConfirmationDialog from "@/components/shared/ConfirmationDialog.vue";

export default {
  name: "TeamDetailHeaderComponent",
  components: {
    BasicDetailHeaderComponent,
    ConfirmationDialog,
  },
  props: {
    teamId: {
      type: String,
      required: true
    }
  },

data() {
  return {
    promises: [this.fetchTeam()],
    routeNames,
    // TODO: BB1248 replace TEAM_DETAIL_TEMP with TEAM_DETAIL
    teamRoute: {name: routeNames.TEAM_DETAIL_TEMP, params: {teamId: this.teamId}},
    editTeamRoute: {name: routeNames.TEAM_OVERVIEW},
    teamsRoute: {name: routeNames.TEAM_OVERVIEW},
    toggleActiveDialog: false,
    deleteDialog: false,
    team: {
      teamName: null,
      active: null,
    },
  }
},
methods: {
  onToggleActiveClick() {
    this.toggleActiveDialog = true
  },
  toggleActive() {
    this.promises.push(
        this.$store.dispatch("teamsModule/toggleTeamActive", this.team)
            .then(() => this.promises.push(this.fetchTeam()))
    )

  },
  onDeleteTeamClick() {
    this.deleteDialog = true
  },
  deleteTeam() {
    this.promises.push(
        this.$store.dispatch("teamsModule/deleteTeam", this.teamId)
            .then(() => this.$router.push(this.teamsRoute))
    )
  },
  fetchTeam() {
    return this.$store.dispatch("teamsModule/fetchTeam", this.teamId)
        .then(result => {
          this.team = result
        })
  }
},
  computed: {
    getToggleActiveDialogTitle() {
      return `Team ${this.team.teamName} ${this.team.active ? "deactiveren" : "activeren"}`
    },
    getToggleActiveDialogDescription() {
      return `Bent u zeker dat u team ${this.team.teamName} wilt ${this.team.active ? "deactiveren" : "activeren"}?`
    },
    getDeleteDialogTitle() {
      return `Team ${this.team.teamName} verwijderen`
    },

    getDeleteDialogDescription() {
      return `Bent u zeker dat u team ${this.team.teamName} wilt verwijderen?`
    },
  }
}
</script>
